<template>
  <v-container
    fluid
    style="
      min-height: 0;
      padding-left: 0px;
      padding-right: 0px;
      background-color: #f3f3f3;
    "
  >
    <article class="page text-left mobile-center">
      <section class="fw-section">
        <div class="container">
          <!-- header -->
          <div class="rowmh">
            <table
              class="table table-borderless"
              style="border: none !important; background-color: #f3f3f3"
            >
              <tr>
                <td>
                  <router-link to="" @click.native="closeStops()"
                    ><span class="material-icons" style="font-size: 36px">
                      chevron_left
                    </span></router-link
                  >
                </td>
                <td style="width: 80%; text-align: left">
                  <div class="headerLine">
                    {{ routeName }}
                  </div>
                  <div class="headerLine2">
                    {{ plannedOn }}
                  </div>
                </td>
                <td style="width: 10%; text-align: right; font-size: 24px">
                  <router-link to="" @click.native="editRoute()">
                    <span class="material-icons"> edit </span></router-link
                  >
                </td>
              </tr>
            </table>
          </div>
          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />
          <div
            class="rowm"
            style="
              float: right;
              padding-bottom: 10px;
              background-color: #f3f3f3;
            "
          >
            <router-link to="/importdata" v-if="!isOptimized">
              <span class="material-icons"> file_upload </span>Import
              data</router-link
            >
          </div>
          <div class="row padding-bottom-1x"></div>
          <!-- header -->

          <div class="rowm">
            <search
              v-if="!isOptimized"
              hints="Add or find addresses"
              v-on:found-address="onSearchResults"
              style="background-color: #f3f3f3; border-color: #c5c5c5"
              :isDisabled="isDisabledSearch"
            ></search>
          </div>

          <stopdatamain :stopId="stopId" v-model="showScheduleForm" />

          <div class="rowm">
            <button
              v-bind="attrs"
              v-on="on"
              v-if="!isOptimized"
              class="btn btnl1 btn-block btn-sm"
              @click="optimizeRoute(true)"
              type="submit"
              :style="isOptimizeButtonActive ? null : 'opacity: 0.5'"
              :disabled="!isOptimizeButtonActive"
            >
              <span class="material-icons white1">timer</span>
              Optimize route
            </button>

            <button
              v-bind="attrs"
              v-on="on"
              class="btn btnl1 btn-block btn-sm"
              @click="sendToDriver()"
              type="submit"
              v-if="isSendToDriversButtonActiveCalc"
              :style="isSendToDriversButtonActive ? null : 'opacity: 0.5'"
            >
              <span class="material-icons white1">send</span>
              Send route to driver
            </button>

            <div v-if="isSendToDriversButtonActive">
              <a @click="cancelOptmization()" style="float: right"
                >Cancel optimization</a
              >
            </div>

            <div class="row padding-bottom-1x"></div>

            <div class="row padding-bottom-2x"></div>

            <table
              class="table table-borderless"
              style="border: none !important"
            >
              <tr>
                <td>
                  <div class="row3">
                    <div class="right" style="padding-top: 5px">
                      <strong>Drivers: {{ getDriversCount }}</strong>
                    </div>
                  </div>
                </td>

                <td style="width: 40%; text-align: right" v-if="!isOptimized">
                  <button
                    v-bind="attrs"
                    v-on="on"
                    class="btn btnl1 btn-block btn-sm"
                    style="margin-left: 25px; font-size: 14px"
                    @click="manageDrivers()"
                    type="submit"
                  >
                    <span class="material-icons white1">people</span>
                    {{ manageDriversMsg }}
                  </button>
                </td>
              </tr>
            </table>
          </div>
          <div class="row3">
            <div class="right" style="padding-top: 5px; padding-left: 20px">
              Start time: {{ routeStartTime }}
            </div>
          </div>
          <div class="row3" v-if="isDriverRoute">
            <div
              class="right"
              style="padding-top: 5px; padding-left: 20px; font-style: italic"
            >
              Driver route (you can't edit)
            </div>
          </div>
          <div class="row padding-bottom-2x"></div>

          <section
            class="widget widget_recent_entries padding-bottom-1x"
            v-if="isOptimized"
          >
            <hr
              class="hr-solid hr-primary"
              style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
            />

            <v-expansion-panels>
              <v-expansion-panel
                v-for="(item, vehicleRouteIdx) in getDriversCount"
                :key="vehicleRouteIdx"
              >
                <v-expansion-panel-header
                  :style="getVehicleRouteHeaderStyle(vehicleRouteIdx)"
                >
                  <div class="row3">
                    <div class="left">
                      <i
                        class="material-icons account_circle text-default"
                        :style="getVehicleRouteHeaderIconStyle(vehicleRouteIdx)"
                      ></i>
                    </div>
                    <div class="right">
                      <strong>
                        {{ getDriversRoute(vehicleRouteIdx)["email"] }} -
                        {{
                          getDriversRoute(vehicleRouteIdx)["vehicle_route_id"]
                        }}
                      </strong>
                      <br />
                      {{
                        getDistanceText(
                          getDriversRoute(vehicleRouteIdx)["distance"]
                        )
                      }}
                      -
                      {{
                        getTimeInFormat(
                          getDriversRoute(vehicleRouteIdx)["duration_total"]
                        )
                      }}
                      <br />
                      {{ getRouteStatusText(vehicleRouteIdx) }}
                    </div>
                  </div>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <v-menu v-if="!isDriverRoute" bottom offset-y>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        color="blue darken-1"
                        text
                        v-bind="attrs"
                        v-on="on"
                        class="text-h6"
                      >
                        Assign route to another driver
                      </v-btn>
                    </template>
                    <v-list>
                      <v-list-item
                        v-for="(item, i) in driversForRoute(vehicleRouteIdx)"
                        :key="i"
                        @click="setNewDriver(vehicleRouteIdx, item.id)"
                      >
                        <v-list-item-title>{{ item.email }}</v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>

                  <table class="table table-sm hoverTable">
                    <tbody>
                      <tr
                        v-for="(stop, i) in getDriversRouteStops(
                          vehicleRouteIdx
                        )"
                        :key="i"
                        @click="editStop(stop.id)"
                      >
                        <th
                          scope="row"
                          style="width: 5%; padding: 14px 0px 0px 3px"
                        >
                          <div class="vl0"></div>
                          <div
                            :style="
                              stop.delivery_status == 0
                                ? stop.priority == 1
                                  ? 'color: #d80d0d;'
                                  : 'color: #000000;'
                                : stop.priority == 1
                                ? 'color: #DD8E8E;'
                                : 'color: #acacac;'
                            "
                          >
                            {{
                              stop.position_on_the_route != 999999
                                ? stop.position_on_the_route
                                : ""
                            }}
                          </div>
                          <div class="vl"></div>
                        </th>
                        <td
                          style="
                            width: 80%;
                            text-align: left;
                            padding: 10px 0px 10px 0px;
                          "
                        >
                          <div style="display: table">
                            <div
                              style="
                                display: inline-block;
                                width: 200px;
                                vertical-align: top;
                              "
                            >
                              <span
                                class="list-text-normal"
                                :style="
                                  stop.delivery_status == 0
                                    ? 'color: #000000;'
                                    : 'color: #acacac;'
                                "
                                >{{ stop.name }}</span
                              >
                            </div>
                            <div style="display: inline-block; width: 100px">
                              <span
                                v-if="stop.delivery_status == 0"
                                class="arrival-time-or-done"
                                >{{ getVisitTime(stop) }}</span
                              >

                              <span
                                v-if="stop.delivery_status != 0"
                                class="arrival-time-or-done"
                              >
                                <div class="texttime">
                                  {{ getTimeInFormatHHmm(stop.delivered_at) }}
                                </div>
                                <i
                                  v-if="stop.delivery_status == 1"
                                  class="material-icons done text-default"
                                  style="
                                    font-size: 20px;
                                    color: #136ef7;
                                    padding-bottom: 8px;
                                  "
                                ></i>

                                <i
                                  v-if="stop.delivery_status == 2"
                                  class="material-icons highlight_off text-default"
                                  style="
                                    font-size: 20px;
                                    color: #d80d0d;
                                    padding-bottom: 8px;
                                  "
                                ></i>
                              </span>
                            </div>
                          </div>

                          <span
                            class="list-text-small"
                            style="padding-left: 0px"
                            >{{ stop.address }}</span
                          >
                          <br />
                          <span
                            class="list-text-small"
                            style="padding-left: 0px"
                          >
                            Drive: {{ getDriveText(stop) }}
                          </span>

                          <div v-if="isErrorMessage(stop)">
                            <span
                              class="text-gray"
                              style="font-size: 0.9em; color: red"
                              >{{ getErrorMessage(stop) }}</span
                            >
                          </div>
                        </td>
                        <td
                          style="
                            width: 3%;
                            text-align: right;
                            padding: 10px 0px 0px 0px;
                          "
                        >
                          <i
                            class="material-icons keyboard_arrow_right text-default"
                            style="
                              font-size: 24px;
                              padding-bottom: 5px;

                              color: rgba(0, 0, 0, 0.54);
                              text-align: right;
                            "
                          ></i>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
            <template v-if="hasNoRouteToStops">
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <div class="row3">
                      <div class="left">
                        <span
                          class="material-icons"
                          style="font-size: 24px; color: #ff0000"
                        >
                          warning
                        </span>
                      </div>
                      <div
                        class="right"
                        style="font-size: 12px; vertical-align: middle"
                      >
                        <strong> No route to stops </strong>
                      </div>
                    </div>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <table class="table table-sm hoverTable">
                      <tbody>
                        <tr
                          v-for="(stop, i) in getDriversRouteStops(-1)"
                          :key="i"
                          @click="editStop(stop.id)"
                        >
                          <th
                            scope="row"
                            style="width: 5%; padding: 14px 0px 0px 3px"
                          >
                            <div
                              :style="
                                stop.delivery_status == 0
                                  ? stop.priority == 1
                                    ? 'color: #d80d0d;'
                                    : 'color: #000000;'
                                  : stop.priority == 1
                                  ? 'color: #DD8E8E;'
                                  : 'color: #acacac;'
                              "
                            ></div>
                          </th>
                          <td
                            style="
                              width: 80%;
                              text-align: left;
                              padding: 10px 0px 10px 0px;
                            "
                          >
                            <div style="display: table">
                              <div
                                style="
                                  display: inline-block;
                                  width: 200px;
                                  vertical-align: top;
                                "
                              >
                                <span
                                  class="list-text-normal"
                                  :style="
                                    stop.delivery_status == 0
                                      ? 'color: #000000;'
                                      : 'color: #acacac;'
                                  "
                                  >{{ stop.name }}</span
                                >
                              </div>
                              <div style="display: inline-block; width: 100px">
                                <span
                                  v-if="stop.delivery_status != 0"
                                  class="arrival-time-or-done"
                                >
                                  <div class="texttime">
                                    {{ getTimeInFormatHHmm(stop.delivered_at) }}
                                  </div>
                                  <i
                                    v-if="stop.delivery_status == 1"
                                    class="material-icons done text-default"
                                    style="
                                      font-size: 20px;
                                      color: #136ef7;
                                      padding-bottom: 8px;
                                    "
                                  ></i>

                                  <i
                                    v-if="stop.delivery_status == 2"
                                    class="material-icons highlight_off text-default"
                                    style="
                                      font-size: 20px;
                                      color: #d80d0d;
                                      padding-bottom: 8px;
                                    "
                                  ></i>
                                </span>
                              </div>
                            </div>

                            <span
                              class="list-text-small"
                              style="padding-left: 0px"
                              >{{ stop.address }}</span
                            >
                            <br />
                            <span
                              class="list-text-small"
                              style="padding-left: 0px"
                            >
                              Drive: {{ getDriveText(stop) }}
                            </span>

                            <div v-if="isErrorMessage(stop)">
                              <span
                                class="text-gray"
                                style="font-size: 0.9em; color: red"
                                >{{ getErrorMessage(stop) }}</span
                              >
                            </div>
                          </td>
                          <td
                            style="
                              width: 3%;
                              text-align: right;
                              padding: 10px 0px 0px 0px;
                            "
                          >
                            <i
                              class="material-icons keyboard_arrow_right text-default"
                              style="
                                font-size: 24px;
                                padding-bottom: 5px;

                                color: rgba(0, 0, 0, 0.54);
                                text-align: right;
                              "
                            ></i>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </template>
          </section>

          <section
            class="widget widget_recent_entries padding-bottom-1x"
            v-if="!isOptimized"
          >
            <!-- <div class="stops_line">
              {{ stopsLine }}
            </div> -->
            <hr
              class="hr-solid hr-primary"
              style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
            />

            <table class="table table-sm hoverTable">
              <tbody>
                <tr
                  v-for="(stop, i) in stops"
                  :key="i"
                  @click="editStop(stop.id)"
                >
                  <th scope="row" style="width: 5%; padding: 14px 0px 0px 3px">
                    {{
                      stop.position_on_the_route != 999999
                        ? stop.position_on_the_route
                        : ""
                    }}
                  </th>
                  <td
                    style="
                      width: 80%;
                      text-align: left;
                      padding: 10px 0px 10px 0px;
                    "
                  >
                    <i
                      class="material-icons room text-default"
                      :style="
                        stop.priority == 1
                          ? 'font-size: 20px; color: #d80d0d;'
                          : 'font-size: 20px; color: #015a66;'
                      "
                    ></i>

                    <span class="list-text-normal" style="padding-left: 5px">{{
                      stop.name
                    }}</span>
                    <br />
                    <span class="list-text-small" style="padding-left: 23px">{{
                      stop.address
                    }}</span>
                    <br />
                    <span class="list-text-small" style="padding-left: 23px">
                      Drive: {{ getDriveText(stop) }}
                    </span>

                    <div v-if="isErrorMessage(stop)">
                      <span
                        class="text-gray"
                        style="font-size: 0.9em; color: red"
                        >{{ getErrorMessage(stop) }}</span
                      >
                    </div>
                  </td>
                  <td
                    style="
                      width: 10%;
                      text-align: right;
                      padding: 10px 0px 0px 10px;
                    "
                  >
                    <i
                      class="material-icons keyboard_arrow_right text-default"
                      style="
                        font-size: 24px;
                        padding-bottom: 5px;

                        color: rgba(0, 0, 0, 0.54);
                        text-align: right;
                      "
                    ></i>
                  </td>
                </tr>
              </tbody>
            </table>
          </section>
        </div>
        <!-- dialog section start -->
        <dialogInfo
          :message="dialogInfo1_message"
          :title="dialogInfo1_title"
          v-model="dialogInfo1_showDialog"
        />
        <Loader v-model="showLoader" />
        <dialogConfirm ref="confirm"></dialogConfirm>
        <!-- dialog section end -->
      </section>
    </article>
  </v-container>
</template>

<script>
import { EventBus } from "./event-bus";
//import Search from "./SearchMaposcope.vue";
import Search from "./SearchTomTom.vue";
import { mapGetters } from "vuex";
import { distance_unit_miles, distance_unit_km } from "../consts";

export default {
  components: {
    Search,
  },
  data() {
    return {
      showScheduleForm: false,
      dialogInfo1_showDialog: false,
      dialogInfo1_message: "",
      dialogInfo1_title: null,
      showLoader: false,
      showDialogStopEdit: "",

      value: "",
      showTooltipSendToDriver: false,
      isSendToDriversButtonActive: false,
      terms: false,
      showDialogReassignDriver: false,
      sheet: true,
      role_items: ["Driver", "Dispatcher"],
      showDialog1: false,
      isConnected: false,
      socketMessage: "",
      disableSearch: false,
      stopId: null,
    };
  },
  sockets: {
    connect() {
      // Fired when the socket connects.
      this.isConnected = true;

      let token = localStorage.getItem("token");
      this.$socket.emit("userLoggedIn", token);
      this.socketMessage = token;
    },

    disconnect() {
      this.isConnected = false;
    },

    messageChannelStopChanged(data) {
      this.socketMessage = data;
      //this.stopChanged(data);
      let routeId = data["data"];

      if (routeId == this.$store.getters.getRouteId) {
        this.$store.dispatch("getStops").then(() => {
          EventBus.$emit("LOAD_ROUTE_EVENT");
        });
      }
    },
    messageChannelDriverRouteChanged(data) {
      let routeId = data["data"];
      if (routeId == this.$store.getters.getRouteId) {
        this.$store.dispatch("getRoute").then(() => {
          //EventBus.$emit("LOAD_ROUTE_EVENT");
        });
      }
    },
    messageChannelDriverPositionChanged(data) {
      //this.socketMessage = new Date(); //data;
      let routeId = data["data"];

      if (routeId == this.$store.getters.getRouteId) {
        //this.getDriversPostions();
        EventBus.$emit("DRIVER_POSITION_CHANGED_EVENT");
      }
      //this.stopChanged(data);
    },
  },
  computed: {
    ...mapGetters("auth", {
      isAuth: "isAuthenticated",
    }),
    isDisabled: function () {
      return !this.terms;
    },
    stopsLine() {
      var msg = this.stopsCount;
      if (this.stopsCount <= 1) {
        msg += " stop";
      } else {
        msg += " stops";
      }
      if (this.routeDurationTotal.length > 0) {
        msg += ", ";
        msg += this.routeDurationTotal;
      }
      if (this.routeDistance.length > 2) {
        msg += ", ";
        msg += this.routeDistance;
      }
      return msg;
    },
    stopsCount() {
      return this.$store.getters.stops.length;
    },
    stops() {
      return this.$store.getters.stops;
    },
    routeName() {
      return this.$store.getters.getRouteName;
    },
    plannedOn() {
      return this.$store.getters.getRouteData("planned_on");
    },
    routeId() {
      return this.$store.getters.getRouteId;
    },
    routeStartTime() {
      var startTime = this.$store.getters.getRouteData("start_time");
      if (startTime == 0) {
        return "now";
      }
      return this.getTimeInFormatHHmm(
        this.$store.getters.getRouteData("start_time")
      );
    },
    routeDistance() {
      return this.getDistanceText(this.$store.getters.getRouteData("distance"));
    },
    routeDurationTravel() {
      return this.getTimeInFormat(
        this.$store.getters.getRouteData("duration_travel")
      );
    },
    routeDurationTotal() {
      return this.getTimeInFormat(
        this.$store.getters.getRouteData("duration_total")
      );
    },
    getDriversCount() {
      return this.$store.getters.getRouteData("drivers");
    },
    getIconColor: function (stop) {
      if (stop.priority == 111) {
        return "material-icons room text-default";
      } else {
        return "material-icons room text-danger";
      }
    },
    isOptimizeButtonActive() {
      var driverCount = this.$store.getters.getRouteData("drivers");
      return driverCount > 0;
    },
    manageDriversMsg() {
      var driverCount = this.$store.getters.getRouteData("drivers");
      if (driverCount == 0) {
        return "Add Drivers";
      } else {
        return "Manage Drivers";
      }
    },
    isOptimized() {
      return (
        this.$store.getters.getRouteData("is_optimized") == 1 ||
        this.$store.getters.getRouteData("is_driver_route") == 1
      );
    },
    isSendToDriversButtonActiveCalc() {
      return this.$store.getters.getRouteData("is_driver_route") != 1;
    },
    isDisabledSearch() {
      return this.disableSearch;
    },
    isDriverRoute() {
      return this.$store.getters.getRouteData("is_driver_route") == 1;
    },
    drivers() {
      return this.$store.getters.drivers;
    },
    hasNoRouteToStops() {
      for (var i = 0; i < this.$store.getters.stops.length; i++) {
        if (this.$store.getters.stops[i]["vehicle_route_id"] == -1) return true;
      }
      return false;
    },
    distanceUnit() {
      return localStorage.getItem("distanceUnit");
    },
  },

  methods: {
    driversForRoute: function (idx) {
      var driverRoutes = this.$store.getters.getRouteData("driver_routes");

      var d = [];
      for (var i = 0; i < this.$store.getters.drivers.length; i++) {
        var is_driver_used = false;
        for (var j = 0; j < driverRoutes.length; j++) {
          if (this.$store.getters.drivers[i].id == driverRoutes[j].driver_id) {
            is_driver_used = true;
          }
        }
        if (!is_driver_used) {
          d.push(this.$store.getters.drivers[i]);
        }
      }
      return d;
    },
    getDriversRoute: function (idx) {
      return this.$store.getters.getRouteData("driver_routes")[idx];
    },
    getDriversRouteStops: function (idx) {
      var stops = [];
      for (var i = 0; i < this.$store.getters.stops.length; i++) {
        if (this.$store.getters.stops[i]["vehicle_route_id"] == idx)
          stops.push(this.$store.getters.stops[i]);
      }
      return stops;
    },
    getVehicleRouteHeaderStyle(vehicleRouteIdx) {
      //var col = this.$store.getters.getRouteColor(vehicleRouteIdx);
      return "font-size: 12px; line-height: 1.4;"; // color: " + col;
    },
    getVehicleRouteHeaderIconStyle(vehicleRouteIdx) {
      var col = this.$store.getters.getRouteColor(vehicleRouteIdx);
      return "font-size: 24px; color: " + col;
    },
    getIconColor2(stop) {
      if (stop.priority == 111) {
        return "material-icons room text-default";
      } else {
        return "material-icons room text-danger";
      }
    },
    async editStop(id) {
      //:to="{ name: 'EditStop', params: { id: item.id } }"
      //this.$router.push({ name: "EditStop", params: { id: id } });
      this.stopId = id;
      this.showScheduleForm = false;
      //await sleep(500);
      await new Promise((r) => setTimeout(() => r(), 500));
      this.showScheduleForm = true;
    },
    getRouteStatusText: function (vehicleRouteIdx) {
      var driver_route = this.getDriversRoute(vehicleRouteIdx);
      if (driver_route["route_status"] != 0) {
        var options = {
          year: "numeric",
          weekday: "short",
          month: "short",
          day: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        };
        if (driver_route["route_status"] == 1) {
          var d = "";
          if (driver_route["started_time"] != null) {
            d = new Date(driver_route["started_time"]).toLocaleDateString(
              "default",
              options
            );
          }
          var text1 = "Started " + d;
          return text1;
        } else if (driver_route["route_status"] == 2) {
          var df = "";
          if (driver_route["finished_time"] != null) {
            df = new Date(driver_route["finished_time"]).toLocaleDateString(
              "default",
              options
            );
          }
          var text2 = "Finished " + df;
          return text2;
        }
        return "";
      } else {
        return "";
      }
    },
    //getDistanceText
    getDistanceText: function (value) {
      if (value == null || value == "" || value == -1) {
        return "-";
      }
      let distanceUnit = localStorage.getItem("distanceUnit");

      let distanceInMeters = value;
      var distanceText = "";
      if (distanceUnit == distance_unit_miles) {
        //miles
        if (distanceInMeters < 1500) {
          var distanceInFt = Math.ceil(distanceInMeters / 0.3048);
          distanceText = `${distanceInFt} ft`;
        } else {
          var distanceInMiles = Math.ceil(distanceInMeters * 0.000621371192);
          distanceText = `${distanceInMiles} m`;
        }
      } else {
        //km
        if (distanceInMeters < 1000) {
          distanceText = `${distanceInMeters} m`;
        } else {
          var distInKm = Math.ceil(distanceInMeters / 1000);
          distanceText = `${distInKm} km`;
        }
      }
      return distanceText;
    },
    getTimeInFormat: function (_SECONDS0) {
      if (_SECONDS0 == null || _SECONDS0 == "" || _SECONDS0 == -1) {
        return "";
      }
      if (_SECONDS0 < 0) return "(" + _SECONDS0 + ")";

      var minutesAll = Math.ceil(_SECONDS0 / 60);
      if (minutesAll >= 60 && minutesAll / 60 > 0) {
        var minues = minutesAll % 60;
        var hours = parseInt(minutesAll / 60, 10);
        if (minues > 0) {
          return `${hours} h ${minues} min`;
        } else {
          return `${hours} h`; //fixme to to translacji np godz.
        }
      } else {
        return `${minutesAll} min`;
      }
    },
    getTimeInFormatHHmm: function (minutes) {
      const zeroPad = (num, places) => String(num).padStart(places, "0");
      var h = zeroPad(parseInt(minutes / 60), 2);
      var m = zeroPad(minutes % 60, 2);

      return `${h}:${m}`;
    },

    onSearchResults: function (params) {
      var stop = {
        name: params.name,
        address: params.address,
        note: "",
        lat: params.lat,
        lng: params.lng,
      };

      this.$store.dispatch("addStopAction", stop).then((response) => {
        if (response.data.err == 0) {
          EventBus.$emit("ADD_MARKER_EVENT", response.data.stop.id);
          this.editStop(response.data.stop.id);
        } else {
          this.showInfo("Information", response.data.message);
          if (response.data.err == -20) {
            this.disableSearch = true;
          }
        }
      });
    },
    closeStops() {
      this.$router.push({ name: "Routes" });
      this.$store.commit("setCurrentRoute", -1);
      EventBus.$emit("LOAD_ROUTE_EVENT");
    },

    sendToDriver() {
      if (!this.isOptimized) {
        this.showInfo("Information", "Press Oprimize route first");
        return;
      }

      this.$store.dispatch("sendToDrivers").then((response) => {
        //TODO - some info ze send already

        this.showInfo("Information", "Route has been sent to drivers");
      });
    },
    manageDrivers() {
      this.$router.push({ name: "ManageDrivers" });
    },

    async showInfo(title, msg) {
      if (
        await this.$refs.confirm.openInfo(title, msg, {
          color: "blue",
        })
      ) {
        // yes
      }
    },

    optimizeRoute0() {
      this.isSendToDriversButtonActive = !this.isSendToDriversButtonActive;
    },
    optimizeRoute(is_route_optimization) {
      if (this.stopsCount < 2) {
        this.showInfo("First add stops", `Please add at least 2 stops`);
        return;
      }

      //EventBus.$emit("LOADING_ON");
      this.showLoader = true;
      this.$store
        .dispatch("optimizeAct", is_route_optimization)
        .then((response) => {
          //EventBus.$emit("LOADING_OFF");
          this.showLoader = false;

          if (response["err"] != 0) {
            this.showInfo("Route can't be optmized", `${response["message"]}`);
            return;
          }
          //if ok
          this.$store.dispatch("getStops").then(() => {
            EventBus.$emit("LOAD_ROUTE_EVENT");

            this.isSendToDriversButtonActive = true;

            this.showInfo("Route optmized!", `Route has been optmized`);
          });
        });
    },

    getDriveText: function (stop) {
      var driveText = `${
        stop.distance != -1 ? this.getDistanceText(stop.distance) : "-"
      },  ${stop.duration != -1 ? this.getTimeInFormat(stop.duration) : "-"}`; //fixme
      return driveText;
    },

    getVisitTimePlanned: function (stop) {
      var visitText = "";
      if (
        (stop.time_window_start == null || stop.time_window_start == 0) &&
        stop.time_window_end != null &&
        stop.time_window_end != 0
      ) {
        visitText = `before ${this.getTimeInFormatHHmm(stop.time_window_end)}`;
      }

      if (
        (stop.time_window_end == null || stop.time_window_end == 0) &&
        stop.time_window_start != null &&
        stop.time_window_start != 0
      ) {
        visitText = `after ${this.getTimeInFormatHHmm(stop.time_window_start)}`;
      }

      if (
        stop.time_window_end != null &&
        stop.time_window_end != 0 &&
        stop.time_window_start != null &&
        stop.time_window_start != 0
      ) {
        visitText = `${this.getTimeInFormatHHmm(
          stop.time_window_start
        )} - ${this.getTimeInFormatHHmm(stop.time_window_end)}`;
      }
      return visitText;
    },

    getVisitTime: function (stop) {
      var visitText = "-";

      if (stop.arrival == 0) {
        var endTime2 = stop.when_visit_start + stop.service_time; //fixme uwaga na przejscie przez polnoc
        visitText = `${this.getTimeInFormatHHmm(
          stop.when_visit_start
        )} - ${this.getTimeInFormatHHmm(endTime2)}`;
      } else {
        var startTime = this.$store.getters.getRouteData("start_time");
        let start = this.getTimeInFormatHHmm(
          startTime + parseInt(stop.arrival / 60)
        );
        var endTime =
          startTime + parseInt(stop.arrival / 60) + stop.service_time;
        if (stop.waiting_time > 0) {
          endTime += parseInt(stop.waiting_time / 60);
        }

        visitText = `${start} - ${this.getTimeInFormatHHmm(endTime)}`;
        if (stop.waiting_time == 0) {
          return visitText;
        } else {
          var lateOrEarlyStr = "";
          if (stop.waiting_time > 0) {
            lateOrEarlyStr = "early ";
          } else {
            lateOrEarlyStr = "late ";
          }
          let wt =
            stop.waiting_time < 0 ? stop.waiting_time * -1 : stop.waiting_time;
          let waiting_time = this.getTimeInFormat(wt);
          return `(${waiting_time}  ${lateOrEarlyStr}) ${visitText}`;
        }
      }
      return visitText;
    },
    getErrorMessage: function (stop) {
      if (stop.arrival == -2 || stop.waiting_time < 0) {
        //NOT_SET_TIME_WRONG_TIME_WINDOW
        return "Cannot reach time window";
      } else if (stop.arrival == -3) {
        //CANT_REACH_STOP
        return "Cannot route to this stop";
      }
      return "";
    },
    isErrorMessage: function (stop) {
      return stop.arrival == -2 || stop.waiting_time < 0 || stop.arrival == -3;
    },
    editRoute() {
      this.$router.push({
        name: "EditRoute",
        params: { id: this.$store.getters.getRouteId },
      });
    },
    updateSendToDriverButton() {
      let is_optimized =
        this.$store.getters.getRouteData("is_optimized") == 1 ||
        this.$store.getters.getRouteData("is_driver_route") == 1;

      if (is_optimized != null && is_optimized == 1) {
        this.isSendToDriversButtonActive = true;
        this.showTooltipSendToDriver = true;
      } else {
        this.isSendToDriversButtonActive = false;
        this.showTooltipSendToDriver = false;
      }
    },

    setNewDriver: function (vehicleRouteIdx, driver_id) {
      this.$store
        .dispatch("saveDriver", {
          vehicle_route_id: vehicleRouteIdx,
          driver_id: driver_id,
        })
        .then(() => {
          //this.updateSendToDriverButton();
          this.$store.dispatch("getRoute").then(() => {
            //EventBus.$emit("LOAD_ROUTE_EVENT");
          });
        });
    },
    pingServer() {
      // Send the "pingServer" event to the server.
      //this.$socket.emit("message", "PING from stops!");
      this.stopChanged("xxx");
    },
    stopChanged: function (data) {
      var delivery_status = this.$store.getters.stops[0].delivery_status;
      delivery_status = (delivery_status + 1) % 3;
      //this.$store.getters.stops[0].stop.delivery_status = delivery_status;
      this.$store.commit("changeStop", delivery_status);
      //this.$store.commit("setCurrentRoute", -1);
    },
    pingServerUserLoggedIn() {
      // Send the "pingServer" event to the server.
      this.sendUserToken();
    },
    sendUserToken() {
      let token = localStorage.getItem("token");
      this.$socket.emit("userLoggedIn", token);
    },

    cancelOptmization() {
      this.$store.dispatch("cancelOptimization").then((response) => {
        //TODO - some info ze send already
        //this.showInfo("Information", "Route has been sent to drivers");
        //if ok
        this.$store.dispatch("getStops").then(() => {
          EventBus.$emit("LOAD_ROUTE_EVENT");

          this.isSendToDriversButtonActive = false;
        });
      });
    },
    mounted() {
      this.pingServerUserLoggedIn();
    },
  },

  created() {
    this.$store.dispatch("getStops").then(() => {
      EventBus.$emit("LOAD_ROUTE_EVENT");
    });
    this.$store.dispatch("getRoute").then(() => {
      this.updateSendToDriverButton();
    });
    this.$store.dispatch("getDrivers").then(() => {});
  },
};
</script>



<style scoped>
.btnl1 {
  color: #ffffff !important;
  background-color: #015a66;
}

.btnl1:hover {
  background-color: #0b6f7a;
}

/* Define the hover highlight color for the table row */
.hoverTable tr:hover {
  /* background-color: #f1f1f1; */
  background-color: #fffcfc;
  cursor: pointer;
  border: #ffffff;
}

.hoverTable {
  border-color: transparent;
}

.row3 {
  display: flex;
}

.left {
  width: 10%;
}

.right {
  width: 75%;
}

.arrival-time-or-done {
  text-align: right;
  font-size: 11px;
  float: right;
  vertical-align: middle;
  display: block;
}

.texttime {
  display: inline-block;
  font-size: 10px;
  padding: 5px 5px 0 0;
}
#div0 {
  margin: 0;
  width: 100%;
}
#div1 {
  height: 30px;
  width: 30%;
  border: solid 1px #000000;
  background-color: #0066cc;
  float: left;
}
#div2 {
  width: 70%;
  border: solid 1px #000000;
  background-color: #66cc00;
}

.vl {
  border-left: 3px solid #0066cc;
  margin-left: 3px;
  height: 35px;
}
.vl0 {
  border-left: 3px solid #0066cc;
  margin-left: 3px;
  height: 15px;
}
</style>



