<template>
  <v-app>
    <v-navigation-drawer
      v-model="drawer"
      absolute
      permanent
      v-bind:width="425"
      app
      v-if="showSidebar"
      style="background-color: #f3f3f3; height: 100%"
    >
      <router-view class="view one" name="sidebar" id="sidebar"></router-view>
    </v-navigation-drawer>
    <v-content>
      <!-- fluid ma-0 pa-0 fill-height -->
      <v-container
        style="
          margin: 0px;
          padding: 0px;
          width: 100%;
          height: 100%;
          background-color: #f3f3f3;
        "
        fluid="true"
      >
        <router-view name="mainContent"></router-view>
      </v-container>
    </v-content>
  </v-app>
</template>

<script>
export default {
  data: () => ({
    drawer: true,
  }),
  computed: {
    showSidebar() {
      if (["Signup"].indexOf(this.$route.name) != -1) return false;
      if (["Login"].indexOf(this.$route.name) != -1) return false;
      if (["ForgotPassword"].indexOf(this.$route.name) != -1) return false;
      if (["ResetPassword"].indexOf(this.$route.name) != -1) return false;
      return true;
    },
  },
};
</script>
