import Vue from 'vue';
import VueResource from 'vue-resource';
import vuetify from './plugins/vuetify';
import VueRouter from 'vue-router';
import './assets/stylesheets/app2.css';
import App from './App.vue';
import BootstrapVue from 'bootstrap-vue';
import VueSimpleAlert from "vue-simple-alert";
//import SearchMaposcope from './components/SearchMaposcope.vue';
import SearchTomTom from './components/SearchTomTom.vue';

import Map from './components/Map.vue';
import VueSocketIO from 'vue-socket.io';

import { router } from './routes';
import { store } from './store/store';

//import './assets/stylesheets/theme.min.css';
import './assets/stylesheets/theme.css';
import './assets/stylesheets/app2.css';
import './assets/stylesheets/vendor/aos.min.css';
import './assets/stylesheets/vendor/material-icons.min.css';
//import './assets/stylesheets/material-components-web.min.css';
import './assets/stylesheets/vendor/font-awesome.min.css';
import './assets/stylesheets/vendor/socicon.min.css';
import './assets/stylesheets/vendor/bootstrap.min.css';
import './assets/stylesheets/vendor/preloader.css';


import 'vue-multiselect/dist/vue-multiselect.min.css'

import './assets/autocomplete-vue/style.css'
import Autocomplete from '@trevoreyre/autocomplete-vue'

export * from "./utils";
export { default as XlsxJson } from "./components/XlsxJson";
export { default as XlsxRead } from "./components/XlsxRead";
import 'leaflet/dist/leaflet.css';
import { Icon } from 'leaflet'
delete Icon.Default.prototype._getIconUrl;
import 'vue2-timepicker/dist/VueTimepicker.css'


Icon.Default.mergeOptions({
    iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
    iconUrl: require('leaflet/dist/images/marker-icon.png'),
    shadowUrl: require('leaflet/dist/images/marker-shadow.png')
});

Vue.use(Autocomplete)
Vue.use(BootstrapVue);
Vue.use(VueSimpleAlert);
Vue.use(VueRouter);
Vue.use(VueResource);




import StopData from './components/StopData.vue'
import StopDataMain from './components/StopDataMain.vue'
import EditStop from './components/EditStop.vue'
import StopDone from './components/StopDone.vue'
import Routes from './components/Routes.vue'
import ManageRoutePlannedDate from './components/ManageRoutePlannedDate.vue'
import DialogInfo from './components/DialogInfo.vue'
import Loader from './components/Loader.vue'

import DialogConfirm from './components/DialogConfirm.vue'

Vue.component('map', Map)
Vue.component('stopdata', StopData)
Vue.component('stopdatamain', StopDataMain)
Vue.component('editStop', EditStop)
Vue.component('stopDone', StopDone)
//Vue.component('search', SearchMaposcope)
Vue.component('search', SearchTomTom)
Vue.component('routes', Routes)
Vue.component('routePlannedDate', ManageRoutePlannedDate)


Vue.component('Loader', Loader)
Vue.component('dialogInfo', DialogInfo)
Vue.component('dialogConfirm', DialogConfirm)


Vue.use(new VueSocketIO({
    debug: false,
    connection: process.env.VUE_APP_API_SERVER,
    vuex: {
        store,
        actionPrefix: 'SOCKET_',
        mutationPrefix: 'SOCKET_'
    },
    cors: {
        origin: process.env.VUE_APP_API_SERVER,
        methods: ["GET", "POST"]
    }
    //options: { path: "/my-app/" } //Optional options
}))

Vue.http.options.crossOrigin = true
Vue.http.headers.common['Access-Control-Allow-Origin'] = '*'

new Vue({
    store: store,
    router: router,
    vuetify: vuetify,
    render: h => h(App),
    data: {
    }
}).$mount('#app')