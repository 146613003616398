import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";
import auth from './modules/auth'

import { instance } from '@/api'

import Stops from "./stops";
import Routes from "./routes";

Vue.use(Vuex);

const ROUTE_COLORS = [
    "#C70039",
    "#4169E1",
    "#00FFFF",
    "#008000",
    "#7F00FF",
    "#FF5F15",
    "#FF00FF",
    "#A52A2A",
    "#000000",
    "#5F9EA0",
    "#00008B",
];

export const store = new Vuex.Store({
    state: {
        count: 2,
        routeId: -1,
        routeName: 'not-set',
        isLoggedIn: !!localStorage.getItem("token"),
        routeData: {},
        drivers: [],//[{ email: 'ddd', }, { email: 'ddd22' }],
        userName: '',
        teamName: '',
    },
    getters: {
        getRouteId(state) {
            return state.routeId;
        },
        getRouteName(state) {
            return state.routeName;
        },
        //getTodoById: (state) => (id) => {
        getRouteData: (state) => (key) => {
            return state.routeData[key];
        },
        getRouteColor: () => (idx) => {
            return ROUTE_COLORS[idx % ROUTE_COLORS.length];
        },
        drivers: state => {
            return state.drivers;
        },
        isOptimized(state) {
            return state.routeData["is_optimized"];
        },

    },
    mutations: {
        clear: (state) => {
            state.Stops.stops = [];
            state.routeId = -1
        },

        restoreState: (state, payload) => {
            state.Portfolio.funds = payload.funds;
            // state.Stocks.stocks = payload.stocks; 
            state.Portfolio.portfolio = payload.portfolio;
        },

        //stops    
        addStop(state, stop) {
            // mutate state

            state.Stops.stops.push(stop)
        },
        editStop(state, stop) {
            for (var i = 0; i < state.Stops.stops.length; i++) {
                if (state.Stops.stops[i].id == this.id) {
                    state.Stops.stops[i].notes = stop.note;
                    break;
                }
            }
        },
        deleteStopStore(state, stopId) {

            for (var i = 0; i < state.Stops.stops.length; i++) {

                if (state.Stops.stops[i].id == stopId) {
                    state.Stops.stops.splice(i, 1);

                    break;
                }
            }
        },
        changeStop(state, delivery_status) {
            var i = 0;
            //for (var i = 0; i < state.Stops.stops.length; i++) {
            //if (state.Stops.stops[i].id == this.id) {
            //this.items.$set(index, val)    

            //state.Stops.stops[i].delivery_status = delivery_status;

            var stop = state.Stops.stops[i];
            stop.name = delivery_status;
            stop.delivery_status = delivery_status;
            state.Stops.stops.set(i, stop);
            //state.Stops.stops.splice(i, 1);

            //   break;
            // }
            //}
        },

        addStops(state, stops) {
            // mutate state
            state.Stops.stops = stops;
        },
        addRoutes(state, routes) {
            // mutate state

            state.Routes.routes = routes;
        },
        setCurrentRoute(state, routeId) {
            state.routeId = routeId;
        },
        setCurrentRouteName(state, title) {
            state.routeName = title;
        },
        // setPreferences(state, data) {
        //     state.preferencesData = data;
        //     
        //     
        // },
        setDisplayName(state, newName) {
            state.preferencesData.name = newName;
        },
        setupRoute(state, routeData) {
            state.routeData = routeData;
        },

        setDrivers(state, drivers) {
            // mutate state
            state.drivers = drivers;
        },

    },
    actions: {

        getDriversPostions(state) {
            const path = '/drivers_position/' + state.getters.getRouteId + '/';

            return new Promise((resolve, reject) => {
                instance.get(path)
                    .then(response => {

                        resolve(response.data);
                    })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        getStops(state) {
            const path = '/stops/' + state.getters.getRouteId + '/';

            return new Promise((resolve, reject) => {
                instance.get(path)
                    .then(response => {
                        state.commit("addStops", response.data.stops);
                        resolve(response);
                    })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },


        addStopAction(state, stop) {
            const path = '/stop/' + state.getters.getRouteId + '/';

            return new Promise((resolve, reject) => {
                instance.post(path, stop, {}).then(response => {

                    if (response.data.err == 0) {
                        state.commit("addStop", response.data.stop);
                    }
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                        //this.getStops();
                    });
            })
        },

        editStop(state, stop) {
            const path = '/stop/' + state.getters.getRouteId + '/';

            return new Promise((resolve, reject) => {
                instance.post(path, stop, {}).then(response => {
                    //
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                        //this.getStops();
                    });
            })

        },

        deleteStopStore(state, stopId) {
            const path = '/stop_delete/' + state.getters.getRouteId + '/';
            instance.post(path, { id: stopId })
                .then(response => {
                    //
                    state.commit("deleteStopStore", response.data.id);
                })
                .catch((error) => {
                    // eslint-disable-next-line

                    //this.getStops();
                });
        },

        duplicateStop(state, stopId) {
            const path = '/stop_copy/' + state.getters.getRouteId + '/';
            return new Promise((resolve, reject) => {
                instance.post(path, { id: stopId }, {}).then(response => {
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        getPhotosStop(state, stopId) {
            const path = '/stop_photos/' + state.getters.getRouteId + '/';
            return new Promise((resolve, reject) => {
                instance.post(path, { id: stopId }, {}).then(response => {
                    resolve(response.data);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        //routes        
        getAllRoutes(state, selectedDate) {
            const path = '/routes2/';
            return new Promise((resolve, reject) => {
                instance.post(path, { date: selectedDate }).then(response => {
                    state.commit("addRoutes", response.data.routes);
                    resolve(response.data);

                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        getAllTeams(state, selectedDate) {
            const path = '/teams2/';
            return new Promise((resolve, reject) => {
                instance.post(path, { date: selectedDate }).then(response => {
                    //state.commit("addRoutes", response.data.routes);
                    resolve(response.data);

                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        //routes        
        getRoutes(state, selectedDate) {
            const path = '/routes/';
            return new Promise((resolve, reject) => {
                instance.post(path, { date: selectedDate }).then(response => {
                    state.commit("addRoutes", response.data.routes);
                    resolve(response.data);

                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },


        getRoute(state) {
            const path = '/route/' + state.getters.getRouteId + '/';
            return new Promise((resolve, reject) => {
                instance.get(path, { route_id: state.getters.getRouteId }).then(response => {
                    resolve(response.data);
                    state.commit("setupRoute", response.data.route);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },


        createNewRoute(state, payload) {
            const path = '/route/';
            return instance.post(path, payload)
                .then(response => {
                    state.commit("setCurrentRoute", response.data.id);
                    state.commit("setCurrentRouteName", response.data.route_name);
                })
                .catch((error) => {
                    // eslint-disable-next-line

                    //this.getStops();
                });
        },


        editRoute(state, payload) {
            const path = '/route/';
            return new Promise((resolve, reject) => {
                instance.post(path, payload).then(response => {
                    state.commit("setCurrentRouteName", payload.name);
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        deleteRoute(state, route_id) {
            const path = '/route_delete/';
            return new Promise((resolve, reject) => {
                instance.post(path, { route_id: route_id }).then(response => {
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        copyRoute(state, route_id) {

            const path = '/route_copy/';
            return new Promise((resolve, reject) => {
                instance.post(path, { route_id: route_id }).then(response => {
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        generateRouteReport(state, route_id) {

            const path = '/get_route_report/' + route_id + '/';
            return new Promise((resolve, reject) => {
                instance.get(path,
                    {
                        responseType: 'arraybuffer',
                        headers: {
                            'Content-Type': 'application/json',
                            'Accept': 'application/pdf'
                        }
                    })
                    .then((response) => {
                        resolve(response.data);
                    })
                    .catch((error) => {
                    });
            })
        },

        savePreferences(state, pref) {
            const path = '/save_preferences/';
            var payload = pref;

            return new Promise((resolve, reject) => {
                instance.post(path, payload).then(response => {
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        getPreferences(state) {

            const path = '/preferences/';
            // instance.get(path)
            //     .then(response => {
            //         

            //         state.commit("setPreferencesName", response.data.preferences.name);
            //         state.commit("setPreferencesEmail", response.data.preferences.email);
            //         state.commit("setPreferencesStartPoint", response.data.preferences);
            //     });

            return new Promise((resolve, reject) => {
                instance.get(path)
                    .then(response => {
                        // state.commit("addStops", response.data.stops);

                        resolve(response);
                    })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },


        getTeam(state) {
            const path = '/team/';

            return new Promise((resolve, reject) => {
                instance.get(path)
                    .then(response => {

                        resolve(response);
                    })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },
        editTeamMember(state, payload) {
            const path = '/save_teammember/';
            return new Promise((resolve, reject) => {
                instance.post(path, payload).then(response => {

                    //state.commit("setCurrentRouteName", payload.name);
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        deleteUser(state, user_id) {
            const path = '/teammember_delete/';
            return new Promise((resolve, reject) => {
                instance.post(path, { user_id: user_id }).then((response) => {
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },



        saveTeamPreferences(state, pref) {
            const path = '/save_teampreferences/';
            var payload = pref;

            //state.auth.teamname
            return new Promise((resolve, reject) => {
                instance.post(path, payload).then(response => {
                    if (payload.name) {
                        state.commit("auth/updateTeam", payload.name.substring(0, 32));
                    }
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        getDrivers(state) {
            const path = '/team_route/' + state.getters.getRouteId + '/';
            return new Promise((resolve, reject) => {
                instance.get(path)
                    .then(response => {

                        resolve(response);
                        state.commit("setDrivers", response.data.team);
                    })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        saveDriver(state, payload0) {
            const path = '/save_driver/';
            var payload = { route_id: state.getters.getRouteId, vehicle_route_id: payload0.vehicle_route_id, driver_id: payload0.driver_id };
            return new Promise((resolve, reject) => {
                instance.post(path, payload).then(response => {
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        saveDrivers(state, drivers) {
            const path = '/save_drivers/';
            var payload = { route_id: state.getters.getRouteId, drivers: drivers };


            return new Promise((resolve, reject) => {
                instance.post(path, payload).then(response => {
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        optimizeAct(state, is_route_optimization) {
            const path = '/optimize/';

            var payload = { route_id: state.getters.getRouteId, is_route_optimization: is_route_optimization };
            return new Promise((resolve, reject) => {
                instance.post(path, payload).then(response => {

                    resolve(response.data);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },


        forgotPassword(state, email) {
            const path = '/forgot-password/';

            var payload = { 'email': email };
            instance.post(path, payload).then(() => {
            });
        },


        resetPassword(state, payload) {
            const path = '/reset-password/';

            //instance.post(path, payload).then(() => {
            //});
            return new Promise((resolve, reject) => {
                instance.post(path, payload).then(response => {

                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        //reject(error);
                    });
            })
        },


        sendToDrivers(state) {
            const path = '/send_to_drivers/';

            var payload = { route_id: state.getters.getRouteId };
            return new Promise((resolve, reject) => {
                instance.post(path, payload).then(response => {

                    resolve(response.data);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        cancelOptimization(state) {
            const path = '/cancel_optimization/';

            var payload = { route_id: state.getters.getRouteId };
            return new Promise((resolve, reject) => {
                instance.post(path, payload).then(response => {

                    resolve(response.data);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        batchGeocode(state, payload) {
            const path = '/batch_geocode/';
            //var payload = { route_id: state.getters.getRouteId, payload0 };
            payload['route_id'] = state.getters.getRouteId;
            //var payload = 
            return new Promise((resolve, reject) => {
                instance.post(path, payload).then(response => {
                    resolve(response);
                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        getCheckout(state) {
            const path = '/create-checkout-session/';
            var payload = {};
            return new Promise((resolve, reject) => {
                instance.post(path, payload)
                    .then(response => {

                        resolve(response);
                    })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },


        getPaymentAccount(state) {
            const path = '/create-portal-session/';
            var payload = {};
            return new Promise((resolve, reject) => {
                instance.post(path, payload)
                    .then(response => {

                        resolve(response);
                    })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },




        getSubscription(state) {
            const path = '/subscription/';
            return new Promise((resolve, reject) => {
                instance.get(path)
                    .then(response => {

                        resolve(response);
                        //state.commit("setDrivers", response.data.team);
                    })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },

        getTeams(state, selectedDate) {
            const path = '/teams/';
            return new Promise((resolve, reject) => {
                instance.post(path, { date: selectedDate }).then(response => {
                    //state.commit("addRoutes", response.data.routes);
                    resolve(response.data);

                })
                    .catch((error) => {
                        // eslint-disable-next-line

                        reject(error);
                    });
            })
        },



        saveTeamData(state, payload0) {
            const path = '/save-team2/';
            var payload = payload0;
            return new Promise((resolve, reject) => {
                instance.post(path, payload)
                    .then(response => {

                        resolve(response);
                    })
                    .catch((error) => {
                        // eslint-disable-next-line

                        //  reject(error);
                    });
            })
        },

    },
    modules: {
        Stops,
        Routes,
        auth
    },
    plugins: [createPersistedState()]
});