import VueRouter from 'vue-router';

import Test from './components/Test.vue';
import TestSidebar from './components/TestSidebar.vue';

import EditStop from './components/EditStop.vue';
import Stops from './components/Stops.vue';
import Map from './components/Map.vue';
import RoutesMain from './components/RoutesMain.vue';
import EditRoute from './components/EditRoute.vue';
import Settings from './components/Settings.vue';
import Preferences from './components/Preferences.vue';
import PersonalProfile from './components/PersonalProfile.vue';
import Login from './components/Login.vue';
import Register from './components/Register.vue';
import ForgotPassword from './components/ForgotPassword'
import Onboarding from './components/Onboarding.vue'
import OnboardingTeamName from './components/OnboardingTeamName.vue'
import OnboardingDepot from './components/OnboardingDepot.vue'
import OnboardingTeamMembers from './components/OnboardingTeamMembers.vue'
import TeamMembers from './components/TeamMembers.vue'
import ManageDrivers from './components/ManageDrivers.vue'
import ImportData from './components/ImportData.vue'
import ManageDriversNewRoute from './components/ManageDriversNewRoute.vue'
import UserHeader from './components/UserHeader.vue'
import UserHeaderSidebar from './components/UserHeaderSidebar.vue'
import ManageRoutePlannedDate from './components/ManageRoutePlannedDate.vue'
import Subscription from './components/Subscription.vue'
import ResetPassword from './components/ResetPassword.vue'
import ConfirmCancel from './components/ConfirmCancel.vue'
import Canceled from './components/Canceled.vue'
import SubscriptionSuccess from './components/SubscriptionSuccess.vue'
import AdminTeams from './components/AdminTeams.vue'
import AdminRoutes from './components/AdminRoutes.vue'

import { instance } from '@/api';


export const routes = [
   //{ path: '/test', name: 'Test', components: { sidebar: TestSidebar, mainContent: Test }, props: true },
   { path: '/preferences', name: 'Preferences', components: { sidebar: UserHeader, mainContent: Preferences }, meta: { requiresAuth: true } },
   { path: '/profile', name: 'profile', components: { sidebar: UserHeader, mainContent: PersonalProfile }, meta: { requiresAuth: true } },
   { path: '/team', name: 'Team', components: { sidebar: UserHeader, mainContent: TeamMembers }, meta: { requiresAuth: true } },
   { path: '/routes', name: 'Routes', components: { sidebar: UserHeaderSidebar, mainContent: RoutesMain }, meta: { requiresAuth: true } },
   { path: '/route', name: 'EditRoute', components: { sidebar: EditRoute, mainContent: Map }, props: true, meta: { requiresAuth: true } },
   { path: '/edit', name: 'EditStop', components: { sidebar: EditStop, mainContent: Map }, props: true, meta: { requiresAuth: true } },
   { path: '/drivers', name: 'ManageDrivers', components: { sidebar: ManageDrivers, mainContent: Map }, props: true, meta: { requiresAuth: true } },
   { path: '/drivers_new', name: 'ManageDriversNewRoute', components: { sidebar: ManageDriversNewRoute, mainContent: Map }, props: true, meta: { requiresAuth: true } },
   { path: '/route_planner', name: 'ManageRoutePlannedDate', components: { sidebar: ManageRoutePlannedDate, mainContent: Map }, props: true, meta: { requiresAuth: true } },
   { path: '/stops', name: 'Stops', components: { sidebar: Stops, mainContent: Map }, meta: { requiresAuth: true } },
   { path: '/importdata', name: 'ImportData', components: { sidebar: ImportData, mainContent: Map }, meta: { requiresAuth: true } },
   { path: '/login', name: 'Login', components: { mainContent: Login } },
   { path: '/signup', name: 'Signup', components: { mainContent: Register } },
   { path: '/forgot-password', name: 'ForgotPassword', components: { mainContent: ForgotPassword } },
   { path: '/reset-password/:resetToken', name: 'ResetPassword', components: { mainContent: ResetPassword } },
   {
      path: '/subscription', name: 'Subscription', components: { sidebar: UserHeader, mainContent: Subscription },
      beforeEnter: (_to, from, next) => {
         let token = localStorage.getItem('token');
         if (token) {
            instance.post('/verify-token3/').then(response => {
               if (response.status == 402) {
                  //noting to-do
               } else {
                  next();
               }
            }).catch(() => {
            })
         }

      }
   },
   { path: '/cancel', name: 'ConfirmCancel', components: { sidebar: UserHeader, mainContent: ConfirmCancel }, meta: { requiresAuth: true } },
   { path: '/canceled', name: 'Canceled', components: { sidebar: UserHeader, mainContent: Canceled }, meta: { requiresAuth: true } },
   { path: '/subscription-success', name: 'SubscriptionSuccess', components: { sidebar: UserHeader, mainContent: SubscriptionSuccess }, meta: { requiresAuth: true } },

   {
      path: '/admin-teams', name: 'AdminTeams', components: { sidebar: UserHeader, mainContent: AdminTeams }, meta: { requiresAuth: true },
      beforeEnter: (to, from, next) => {
         let token = localStorage.getItem('token');
         let requireAuth = to.matched.some(record => record.meta.requiresAuth);

         if (!requireAuth) {
            next();
         }

         if (requireAuth && !token) {
            next('/login');
         }

         if (requireAuth && token) {
            instance.post('/verify-token2/').then(response => {
               if (response.status == 402) {
                  //next();
                  next('/login');
               } else {
                  next();
               }
            }).catch(() => {
               next('/login');
            })
         }
      }
   },

   {
      path: '/admin-routes', name: 'AdminRoutes', components: { sidebar: UserHeader, mainContent: AdminRoutes }, meta: { requiresAuth: true },
      beforeEnter: (to, from, next) => {
         let token = localStorage.getItem('token');
         let requireAuth = to.matched.some(record => record.meta.requiresAuth);

         if (!requireAuth) {
            next();
         }

         if (requireAuth && !token) {
            next('/login');
         }

         if (requireAuth && token) {
            instance.post('/verify-token2/').then(response => {
               if (response.status == 402) {
                  //next();
                  next('/login');
               } else {
                  next();
               }
            }).catch(() => {
               next('/login');
            })
         }
      }
   },

   {
      path: '/onboarding', name: 'OnboardingTeam', components: { sidebar: Onboarding, mainContent: OnboardingTeamName }, meta: { requiresAuth: true },
      beforeEnter: (_to, from, next) => {
         if (from.name === 'Signup') {
            next()
         } else {
            next('/routes');
         }
      }
   },
   {
      path: '/onboardingdepot', name: 'OnboardingDepot', components: { sidebar: Onboarding, mainContent: OnboardingDepot }, meta: { requiresAuth: true },
      beforeEnter: (_to, from, next) => {
         if (from.name === 'OnboardingTeam') {
            next()
         } else {
            next('/routes');
         }
      }
   },
   {
      path: '/onboardingteammembers', name: 'OnboardingTeamMembers', components: { sidebar: Onboarding, mainContent: OnboardingTeamMembers },
      meta: { requiresAuth: true }, beforeEnter: (_to, from, next) => {
         if (from.name === 'OnboardingDepot') {
            next()
         } else {
            next('/routes');
         }
      }
   },

   {
      path: "*", beforeEnter: (_to, from, next) => {
         next('/login');
      }
   },
];

const router = new VueRouter({
   mode: 'history',
   routes: routes,
});

router.beforeEach((to, from, next) => {
   let token = localStorage.getItem('token');
   let requireAuth = to.matched.some(record => record.meta.requiresAuth);

   if (!requireAuth) {
      next();
   }

   if (requireAuth && !token) {
      next('/login');
   }

   if (to.path === '/login') {
      if (token) {

         instance.post('/verify-token/').then(response => {
            if (response.status == 401) {
               next();
            } else {
               next('/routes');
            }
         }).catch((error) => {
            // eslint-disable-next-line

            //reject(error);
            //});
            next();
         }).catch(() => {

            next();
         });
      }
      else {
         next();
      }
   } else {
      /*if (requireAuth && token) {
         next();
      }*/
      if (requireAuth && token) {
         instance.post('/verify-token/').then(response => {
            if (response.status == 402) {
               //next();
               next('/subscription');
            } else {
               next();
            }
         }).catch(() => {
            next('/login');
         })
      }
   }
   // if (requireAuth && token) {
   //    instance.post('/verify-token/').then(() => {
   //       next();
   //    }).catch(() => {
   //       next('/login');
   //    })
   // }
});


instance.interceptors.response.use((response) => {
   return response
}, async (error) => {
   switch (error.response.status) {
      case 401:
         router.push('/login');
         break;
      default:
         break;
   }
   return error.response;
});

export { router };