<template>
  <v-container
    fluid
    style="
      min-height: 0;
      padding-left: 0px;
      padding-right: 0px;
      background-color: #f3f3f3;
    "
  >
    <article class="page text-left mobile-center">
      <section class="fw-section">
        <div class="container">
          <!-- header -->
          <div class="rowmh">
            <table
              class="table table-borderless"
              style="border: none !important"
            >
              <tr>
                <td>
                  <router-link to="" @click.native="save()"
                    ><span class="material-icons" style="font-size: 36px">
                      chevron_left
                    </span></router-link
                  >
                </td>
                <td style="width: 80%; text-align: left">
                  <div class="headerLine">Edit route details</div>
                </td>
                <td>
                  <div class="headerLine3">
                    <button
                      class="btn btn-danger btn-block btn-ghost btn-sm"
                      @click="deleteRoute()"
                      type="submit"
                      :disabled="isOptimized"
                    >
                      Delete route
                    </button>
                  </div>
                </td>
              </tr>
            </table>
          </div>
          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />
          <div class="row padding-bottom-1x"></div>
          <!-- header -->

          <!-- <v-dialog v-model="dialog"  transition="dialog-bottom-transition" width="80%" fullscreen hide-overlay > -->

          <v-expansion-panels accordion :disabled="isOptimized">
            <v-expansion-panel>
              <v-expansion-panel-header>
                <div style="font-size: 14px">
                  <i
                    class="material-icons alarm"
                    style="
                      font-size: 14px;
                      padding-bottom: 5px;
                      padding-right: 5px;
                      color: #002e36;
                    "
                  ></i
                  >{{ selectedDate }}
                </div></v-expansion-panel-header
              >
              <v-expansion-panel-content id="innerExPan">
                <center>
                  <v-date-picker
                    v-model="selectedDate"
                    @dayclick="onDayClick"
                    v-on:change="onDate"
                    no-title
                    style="min-height: 460px"
                    width="350"
                  />
                </center>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>

          <br />
          <div class="rowm">
            <input
              id="nameModel"
              name="nameModel"
              v-model="nameModel"
              required
              type="text"
              value=""
              placeholder="enter route name"
              style="background-color: #ffffff; border-color: #c5c5c5"
              :disabled="isOptimized"
            />

            <label for="name">Note</label>
            <textarea
              cols="40"
              id="noteModel"
              name="noteModel"
              rows="2"
              v-model="noteModel"
              placeholder="add notes"
              style="background-color: #ffffff; border-color: #c5c5c5"
              :disabled="isOptimized"
            ></textarea>
          </div>
          <br />

          <hr
            class="hr-solid hr-primary"
            style="border-top-color: ; border-top-width: 1px; opacity: 0.25"
          />
          <div class="rowm">
            <label for="name">Start time:</label>
            <vue-timepicker
              v-model="timeValueStartTime"
              format="HH:mm"
              @change="changeHandlerStartTime"
              :disabled="isOptimized"
            ></vue-timepicker>
            <br /><br />
            <label>Start Point:</label> {{ startAddress }}

            <search
              hints="Find new start location"
              v-on:found-address="onSearchResultsStartLocation"
              :isDisabled="isOptimized"
            ></search>

            <label>End Point:</label> {{ endAddress }}
            <div>
              <search
                hints="Find new end location"
                v-on:found-address="onSearchResultsEndLocation"
                :isDisabled="isOptimized"
              ></search>
            </div>
          </div>
          <dialogInfo
            :message="dialogInfo1_message"
            :title="dialogInfo1_title"
            v-model="dialogInfo1_showDialog"
          />
          <dialogConfirm ref="confirm"></dialogConfirm>
        </div>
      </section>
    </article>
  </v-container>
</template>

<script>
import VueTimepicker from "vue2-timepicker";

export default {
  components: { VueTimepicker },
  data() {
    return {
      id: 0,
      nameModel: "not set",
      noteModel: "",

      timeValueStartTime: "",

      startAddress: "",
      endAddress: "",
      showDialog1: false,
      selectedDate: null,
      dialogInfo1_showDialog: false,
      dialogInfo1_message: "",
      dialogInfo1_title: null,
      is_driver_route: false,
    };
  },
  methods: {
    showInfo(message) {
      this.dialogInfo1_showDialog = true;
      this.dialogInfo1_message = message;
      this.dialogInfo1_title = null;
    },
    changeHandlerStartTime(eventData) {
      if (!eventData.data.HH) {
        this.timeValueStartTime = "08:" + eventData.data.mm;
      }
      if (!eventData.data.mm) {
        this.timeValueStartTime = eventData.data.HH + ":00";
      }
    },
    onSearchResultsStartLocation: function (params) {
      this.startAddressLat = params.lat;
      this.startAddressLng = params.lng;
      this.startAddress = params.fullAddress;
    },
    onSearchResultsEndLocation: function (params) {
      this.endAddressLat = params.lat;
      this.endAddressLng = params.lng;
      this.endAddress = params.fullAddress;
    },

    save() {
      if (!this.is_driver_route) {
        if (this.startAddress == null || this.startAddress.length == 0) {
          this.showInfo("The start address must be established");
          return;
        }
        if (this.endAddress == null || this.endAddress.length == 0) {
          this.showInfo("The end address must be established");
          return;
        }
      }

      if (!this.nameModel != null && this.nameModel.length > 0) {
        var payload = {
          route_id: this.$store.getters.getRouteId,
          name: this.nameModel,
          desc: this.noteModel,
          planned_on: this.selectedDate,
        };
        if (this.timeValueStartTime) {
          var t = this.timeValueStartTime.split(":");
          var mins = parseInt(t[0]) * 60 + parseInt(t[1]);
          payload.start_time = mins; //e.g. 08:23
        }

        if (this.startAddress != null && this.startAddress.length > 0) {
          payload.start_point_address = this.startAddress;
          payload.start_point_lat = this.startAddressLat;
          payload.start_point_lng = this.startAddressLng;
        }
        if (this.endAddress != null && this.endAddress.length > 0) {
          payload.end_point_address = this.endAddress;
          payload.end_point_lat = this.endAddressLat;
          payload.end_point_lng = this.endAddressLng;
        }
        this.$store.dispatch("editRoute", payload).then(() => {
          this.$router.go(-1);
        });
      } else {
        this.showInfo("Route name cannot be empty");
      }
    },
    async deleteRoute() {
      let route_id = this.$store.getters.getRouteId;
      /*this.$confirm("Do you really want to delete the route?").then(() => {
        this.$store.dispatch("deleteRoute", route_id).then(() => {
          this.$router.push({
            name: "Routes",
          });
        });
      });*/
      if (
        await this.$refs.confirm.open(
          "Delete",
          "Do you really want to delete the route?",
          {
            color: "blue",
          }
        )
      ) {
        // yes
        this.$store.dispatch("deleteRoute", route_id).then(() => {
          this.$router.push({
            name: "Routes",
          });
        });
      } else {
        // cancel
      }
    },
  },
  computed: {
    count() {
      return this.$store.state.count;
    },
    isOptimized() {
      return (
        this.$store.getters.getRouteData("is_optimized") == 1 ||
        this.is_driver_route
      );
    },
  },
  mounted() {},
  created() {
    this.id = this.$route.params.id;
    this.$store.dispatch("getRoute").then((response) => {
      this.nameModel = response.route.name;
      this.noteModel = response.route.desc;
      const zeroPad = (num, places) => String(num).padStart(places, "0");
      this.timeValueStartTime =
        zeroPad(parseInt(response.route.start_time / 60), 2) +
        ":" +
        zeroPad(response.route.start_time % 60, 2);

      this.startAddress = response.route.start_point_address;
      this.startAddressLat = response.route.start_point_lat;
      this.startAddressLng = response.route.start_point_lng;
      this.endAddress = response.route.end_point_address;
      this.endAddressLat = response.route.end_point_lat;
      this.endAddressLng = response.route.end_point_lng;
      this.selectedDate = this.$store.getters.getRouteData("planned_on");
      this.is_driver_route = response.route.is_driver_route == 1;
    });
  },
};
</script>